import React from 'react';
import Modal from 'react-modal';
import { Incomplete } from '../icons';
// import classes from './cashbackLearnMore.module.scss';
import { Button, ButtonTypes } from '../common/Button';

import cashbackImage from '../../assets/img/Cashback-Light.svg';
import { StyledLink } from '../common/Link';
import { StyledLinkTypes } from '../common/Link/StyledLink.constants';

export const CashbackLearnMore = ({
	openModal,
	setModal
}: {
	openModal: boolean;
	setModal: (open: boolean) => void;
}) => {
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '564px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			backgroundColor: '#fff',
			color: '#fff',
			borderColor: '#001b47'
		}
	};
	return (
		<Modal
			isOpen={openModal}
			style={customStyles}
			onRequestClose={() => {}}
			contentLabel="Confirm Beneficiary"
			shouldCloseOnEsc={false}
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="d-flex flex-column">
				<div className="modal-header">
					<div
						onClick={() => {
							setModal(false);
						}}
						className="modal-close-btn-container"
					>
						<Incomplete></Incomplete>
					</div>
					<div className={`text-h3-325 text-white text-center mt-32`}>What is Cash Back? </div>
				</div>
				<div className="modal-body d-flex flex-column justify-content-center align-items-center">
					<img src={cashbackImage} />
					<p className="text-primary text-h4-325 mt-32">Get 2% Cash Back</p>
					<div className="text-primary py-16">
						<p className="text-center text-base-325">
							Use your InComm Benefits card and get cash back on all eligible purchases.
						</p>
						<br />
						<p className="text-center text-base-325">
							Cash back will accumulate each month and can be redeemed at any time. Once redeemed,
							cash back will be deposited into your HSA, helping to build your balance for future
							spending or investing.
						</p>
					</div>
					<hr aria-hidden="true" className="w-100" />
					<p className="text-sm-325 py-16 text-primary">
						There’s two ways the cash back will be deposited into your HSA.
					</p>

					<p className="text-base-700 text-primary w-100">Cash back as a credit:</p>
					<p className="text-sm-325 text-primary">
						For any transaction paid in full or in part by your HSA, the cash back will be deposited
						into your HSA as a credit and will reduce the gross distribution amount reported on your
						1099-SA tax form.
					</p>

					<p className="text-base-700 text-primary mt-16 w-100">Cash back as a contribution:</p>
					<p className="text-sm-325 text-primary">
						For any transaction paid in full or in part by your HSA Backup account, the cash back
						will appear as a contribution. This will count towards your annual contribution limit
						and will be reflected on your 5498-SA tax form, ultimately giving you additional tax
						savings.
					</p>

					<hr aria-hidden="true" className="w-100" />
					<p className="text-center text-base-325 text-primary">
						To learn even more about earning cash back, check out our
						<StyledLink
							type={StyledLinkTypes.TERTIARY}
							href="https://www.incomm.com/benefits/cash-back-faq/ "
						>
							FAQs.
						</StyledLink>
					</p>
					<div className="p-16 w-100 d-flex justify-content-center">
						<Button
							type={ButtonTypes.PRIMARY}
							onClick={() => {
								console.log('closing modal');
								setModal(false);
							}}
						>
							Close
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};
