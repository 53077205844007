import classes from './FixMistakenWithdrawalPage.module.scss';
import completedSubmissionIllustration from '../../assets/img/ThumbsUpLight.svg';
import { Button, ButtonTypes } from '../../components/common/Button';
import { useNavigate } from 'react-router-dom';
import { ReimburseTo } from '../../constants/common';
import { getDecimalVal } from '../../utils/numberUtil';

interface ISubmissionCompleteProps {
	id: string;
	taxYear: string;
	selectedAccount: string | undefined;
	mistakenAmount?: string;
}
const FixWithdrawalSubmissionComplete: React.FC<ISubmissionCompleteProps> = ({
	id,
	taxYear,
	selectedAccount,
	mistakenAmount
}) => {
	const navigate = useNavigate();
	return (
		<div
			className={`d-flex flex-column align-items-center p-16 text-center ${classes['fix-withdrawal-wrapper']}`}
		>
			<div className={`d-flex justify-content-between align-items-center mx-auto mt-128`}>
				<p className="text-h3-325 text-white">Fix Mistaken Withdrawal</p>
			</div>
			<div className="text-h1-325 text-yellow mt-40">Submission Complete</div>
			<div className="my-48">
				<img src={completedSubmissionIllustration} width="200px" />
			</div>
			<div className="text-primary text-base-325 mb-96">
				<p className="mb-12">
					{selectedAccount == ReimburseTo.EVERYDAY ? (
						<strong>
							${getDecimalVal(Number(mistakenAmount))} has been transferred from your HSA Backup
							account to your HSA.
						</strong>
					) : (
						<>
							{' '}
							<strong>Please allow 4-5 business days</strong> for the transfer to be completed.
						</>
					)}
				</p>
				<p>The correction you have submitted will be reflected on your {taxYear} tax forms.</p>
			</div>
			<div className={`d-flex w-100 flex-column ${classes['btn-wrapper']}`}>
				<Button
					type={ButtonTypes.PRIMARY}
					onClick={() => {
						navigate(`/apps/expense?id=${id}`);
					}}
				>
					View Expense
				</Button>
				<Button
					type={ButtonTypes.SECONDARY}
					onClick={() => {
						navigate('/apps/dashboard');
					}}
				>
					Return to Dashboard
				</Button>
			</div>
		</div>
	);
};

export default FixWithdrawalSubmissionComplete;
