/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import classes from './DashboardPage.module.scss';
import { Button, ButtonSizes, ButtonTypes } from '../../components/common/Button';
import zendaCardImage from '../../assets/img/debit-card-activation.png';
import { MyAccountDetail } from '../../components/MyAccountDetail';
// import { RecentTransaction } from '../../components/RecentTransaction';
import { TransactionTableV2 } from '../../components/common/TransactionTableV2';
import { Fsa, Hsa, Everyday, Invest, Incomplete, ChevronClose } from '../../components/icons';
import { dashboardApi } from '../../services/dashboardService';
import { contactApi } from '../../services/profileService';
import { cardApi } from '../../services/cardApi';
import { groupTransaction } from '../../utils/transactionsUtil';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ICard } from '../../interface/common.interface';
import {
	AccountType,
	CardStatus,
	AccountStatus,
	ExpenseGroupType,
	AccountPlanStatus
} from '../../constants/common';
import { StyledLink } from '../../components/common/Link';
import { Loader } from '../../components/common/Loader';
import Modal from 'react-modal';
import { PhoneUpdateSuccess } from '../../components/common/modal/PhoneUpdateSuccess';
import { EditPhoneNumber } from '../../components/common/EditPhoneNumber';
import { usePlaid } from '../../hooks/usePlaid';
import ConfirmationImage from '../../assets/img/ThumbsUpLight.svg';
import linkBankAccountError from '../../assets/img/error.svg';
import { ContactUs } from '../../components/ContactUs';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountSummary } from '../../store/accountSummarySlice';
import { StyledLinkTypes } from '../../components/common/Link/StyledLink.constants';
import Slider from 'react-slick';
import { expenseApi } from '../../services/expenseService';
import { dateUtil } from '../../utils/dateUtil';
import { isAccountClosed } from '../../utils/accountUtils';
import { Helmet } from 'react-helmet';
import { getDecimalVal } from '../../utils/numberUtil';

type CardType = {
	id: string;
	image: string;
	title: string;
	description: Array<string>;
	action: () => void;
	actionTitle: string;
	dismissAction: () => void;
	hideDismiss?: boolean;
};

export const DashboardPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();

	const [transactionList, setTransactionList] = useState<{
		transactions: Array<any>;
		number_of_transaction: number;
	}>({
		transactions: [],
		number_of_transaction: 0
	});
	const [dashboardData, setDashboardData] = useState({
		summary: [],
		external_account_linked: false
	});

	const [primaryCard, setPrimaryCard] = useState<ICard | null>(null);
	const [isOnlyDFSAccount, setIsOnlyDFSAccount] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [phoneModalIsOpen, setPhoneModalOpen] = React.useState<boolean>(false);
	const [isPhoneUpdated, setPhoneUpdated] = React.useState<boolean>(false);
	const [isPhoneVerified, setPhoneVerified] = React.useState<boolean>(false);
	const { networkResponse, networkLoading, networkError } = usePlaid('bank-account');
	const [linkSuccessModalIsOpen, setLinkSuccessModalIsOpen] = React.useState<boolean>(false);
	const [invalidAccountModalIsOpen, setInvalidAccountModalIsOpen] = React.useState<boolean>(false);
	const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
	const [profile, setProfile] = useState<any>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [modalIsOpen, setIsOpen] = React.useState<boolean>(
		searchParams.get('isActivateCard') ? true : false
	);
	const [phoneAddedOpen, setPhoneAdded] = React.useState<boolean>(
		searchParams.get('isPhoneActive') ? true : false
	);
	const [isNewAccount] = React.useState<boolean>(searchParams.get('isNewAccount') ? true : false);

	const [activateCardItems, setActivateCardItems] = React.useState<Array<CardType>>([]);

	const [travelCardItems, setTravelCardItems] = React.useState<Array<CardType>>([]);

	const [accountPlanCardItems, setAccountPlanCardItems] = React.useState<Array<CardType>>([]);

	const [slickSetting, setSlickSetting] = useState({
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1
	});

	const [isHsaAccountClosed, setIsHsaAccountClosed] = useState<boolean>(false);

	const handleRedirectionForLink = () => {
		navigate('/apps/linkaccount');
	};
	const getAccountSummaryData = (data: any) => {
		// .filter((account: any) => {
		// 	if (
		// 		account.status == AccountStatus.ACTIVE ||
		// 		(account.status == AccountStatus.INACTIVE &&
		// 			[AccountType.INVESTMENT].includes(account.type))
		// 	) {
		// 		return true;
		// 	}
		// })
		const accountData = JSON.parse(
			JSON.stringify(
				data?.investment_summary
					? [...data.xfsa_summary, ...data.hsa_summary, data?.investment_summary]
					: [...data.xfsa_summary, ...data.hsa_summary]
			)
		).map((account: any) => {
			switch (account.type) {
				case AccountType.HSA:
					account.icon = Hsa;
					account.text = account.account_title;
					account.link = `/apps/hsa/dashboard`;
					account.textDetail = '';
					break;
				case AccountType.EVERYDAY:
					account.icon = Everyday;
					account.text = account.account_title;
					account.link = `/apps/everyday/dashboard`;
					account.button =
						!data.external_account_linked &&
						account.balance == 0 &&
						account.account_status != AccountStatus.CLOSED
							? 'Link'
							: '';
					account.buttonHandler = handleRedirectionForLink;
					account.isDisabled = isAccountClosed(data);
					break;
				case AccountType.INVESTMENT:
					account.icon = Invest;
					account.text = account.account_title;
					account.link = '/apps/investment';
					account.button =
						account.account_status == AccountStatus.ACTIVE ||
						account.account_status == AccountStatus.CLOSED ||
						(account.account_status == AccountStatus.INACTIVE && isAccountClosed(data))
							? ''
							: 'Open';
					account.balance =
						account.account_status == AccountStatus.INACTIVE && isAccountClosed(data)
							? null
							: account.balance;
					account.isDisabled =
						account.account_status == AccountStatus.INACTIVE && isAccountClosed(data);
					break;
				case AccountType.FSA:
					account.icon = Fsa;
					account.text = account.account_title;
					account.link = `/apps/fsa/dashboard?plan_id=${account.plan_id}`;
					account.summary = account;
					break;
				case AccountType.LFSA:
					account.icon = Fsa;
					account.text = account.account_title;
					account.link = `/apps/lfsa/dashboard?plan_id=${account.plan_id}`;
					account.summary = account;
					break;
				case AccountType.DCFSA:
					account.icon = Fsa;
					account.text = account.account_title;
					account.link = `/apps/dcfsa/dashboard?plan_id=${account.plan_id}`;
					account.summary = account;
					break;
			}

			return account;
		});
		console.log('accountData', accountData);
		setDashboardData({
			summary: accountData,
			external_account_linked: data.external_account_linked
		});
	};

	const summaryData = useSelector((state: any) => state.summaryData);

	useEffect(() => {
		setIsHsaAccountClosed(isAccountClosed(summaryData.data));
	}, [summaryData.data]);

	const getTransaction = () => {
		dashboardApi
			.getTransactionV2('all', { offset: 0, limit: 10 })
			.then((response: any) => {
				setTransactionList({
					transactions: groupTransaction(
						response.data.data.transactions || [],
						response.data.data.ach_transactions
					),
					number_of_transaction:
						(response.data.data?.transactions.length || 0) +
						(response.data.data?.ach_transactions.length || 0)
				});
			})
			.catch();
	};

	// Call the API to activate the card
	const handleActivationCard = () => {
		const patchData = {
			card_id: primaryCard?.card_id,
			action: CardStatus.ACTIVATE
		};
		cardApi
			.updateCard(patchData)
			.then(() => {
				const tmpPrimaryCard: ICard = { ...primaryCard, is_activated: true };
				setPrimaryCard(tmpPrimaryCard);
				setPhoneModalOpen(true);
			})
			.catch(error => {
				console.log(error);
			});
	};

	// Call the API to get all the linked cards of the user
	const getCards = () => {
		console.log('calling get card');
		cardApi
			.fetchCards()
			.then(result => {
				setPrimaryCard(() => result?.data?.data?.cards.filter((card: ICard) => card.is_primary)[0]);
			})
			.catch(error => {
				console.log(error);
			});
	};

	const getProfile = () => {
		contactApi
			.contactInformation()
			.then(response => {
				setProfile(response.data.data);
			})
			.catch();
	};

	useEffect(() => {
		setSlickSetting({
			infinite: false,
			slidesToShow:
				[...activateCardItems, ...travelCardItems, ...accountPlanCardItems].length > 1 ? 1.15 : 1,
			slidesToScroll: 1
		});
	}, [activateCardItems, travelCardItems, accountPlanCardItems]);

	// const removeCard = (cardTitle: string) => {
	// 	setCardItems(allCardItems => allCardItems.filter(item => item.title !== cardTitle));
	// };

	const removeAccountCards = (id: string) => {
		setAccountPlanCardItems(accountPlanCard => accountPlanCard.filter(account => account.id != id));
	};

	const setAccountCards = (summary: Array<any>) => {
		if (isHsaAccountClosed) {
			const accountClosedCard = {
				id: `account-closed`,
				image: '',
				title: `This account has been closed.`,
				description: [
					`You have limited access. You can only view your transaction history, statements and tax forms while using the app.`
				],
				action: () => {},
				actionTitle: '',
				dismissAction: () => {},
				hideDismiss: true
			};
			setAccountPlanCardItems([accountClosedCard]);
			return;
		}
		if (summaryData.data.TUserPlanStatus == AccountPlanStatus.PERM_CLOSED) {
			const accountClosedCard = {
				id: `account-closed`,
				image: '',
				title: `This account has been closed.`,
				description: [`You will have limited access to view your transactions history.`],
				action: () => {},
				actionTitle: '',
				dismissAction: () => {},
				hideDismiss: true
			};
			setAccountPlanCardItems([accountClosedCard]);
			return;
		}
		if (summaryData.data.TUserPlanStatus == AccountPlanStatus.READY_TO_CLOSE) {
			const accountClosedCard = {
				id: `account-closed`,
				image: '',
				title: `This account has been closed.`,
				description: [
					`Your run out period has ended. You may reconcile outstanding claims until your plan closes at the end of the benefit year.`
				],
				action: () => {},
				actionTitle: '',
				dismissAction: () => {},
				hideDismiss: true
			};
			setAccountPlanCardItems([accountClosedCard]);
			return;
		}
		const cards = [];

		const suspendedCardList: CardType[] = [];

		const graceCard: CardType = {
			id: 'grace-card-dismissed',
			image: '',
			title: 'Your FSA countdown!',
			description: [],
			dismissAction: () => {
				localStorage.setItem('grace-card-dismissed', 'true');
				removeAccountCards('grace-card-dismissed');
			},
			action: () => {},
			actionTitle: ''
		};

		const runOutCard: CardType = {
			id: 'runout-card-dismissed',
			image: '',
			title: 'You still have time...',
			description: [],
			dismissAction: () => {
				localStorage.setItem('runout-card-dismissed', 'true');
				removeAccountCards('runout-card-dismissed');
			},
			action: () => {},
			actionTitle: ''
		};

		summary.forEach(account => {
			if (
				(account.plan_status == AccountPlanStatus.GRACE ||
					(dateUtil
						.day()
						.isBetween(
							dateUtil.day(account.plan_end_date).subtract(30, 'day').format('YYYY-MM-DD'),
							account.plan_end_date,
							'day',
							'(]'
						) &&
						account.plan_status == AccountPlanStatus.ACTIVE)) &&
				account.balance != 0
			) {
				graceCard.title = `Your ${account.year || ''} FSA countdown!`;
				graceCard.description.push(
					`You have until ${dateUtil.getFormattedDate(
						account.grace_period_end_date,
						'MMMM DD, YYYY'
					)} to spend your ${account.year || ''} ${account.type.toUpperCase()} dollars!`
				);
			} else if (account.plan_status == AccountPlanStatus.RUNOUT && account.balance != 0) {
				runOutCard.description.push(
					`Your ${
						account.year || ''
					} ${account.type.toUpperCase()} plan has ended. But, you can still get reimbursed for your ${
						account.year || ''
					} expenses. You have until ${dateUtil.getFormattedDate(
						account.run_out_end_date,
						'MMMM DD, YYYY'
					)} to submit expenses that were incurred in ${account.year || ''}.`
				);
			} else if (account.plan_status == AccountPlanStatus.SUSPENDED) {
				suspendedCardList.push({
					id: `suspended-card-dismissed-${account.plan_id}-${account.year}-${account.type}`,
					image: '',
					title: `Your debit card is suspended for your ${
						account.year || ''
					} ${account.type.toUpperCase()}.`,
					description: [
						`You need to resolve any expenses awaiting action. You can't use your debit card for your FSA funds until you resolve the issue(s).`
					],
					action: () => {
						navigate('/apps/expenses/summary');
					},
					actionTitle: 'Fix Now',
					dismissAction: () => {
						localStorage.setItem(
							`suspended-card-dismissed-${account.plan_id}-${account.year}-${account.type}`,
							'true'
						);
						removeAccountCards(
							`suspended-card-dismissed-${account.plan_id}-${account.year}-${account.type}`
						);
					}
				});
			}
		});

		suspendedCardList.forEach(cardItem => {
			if (!localStorage.getItem(cardItem.id)) {
				cards.push(cardItem);
			}
		});

		if (runOutCard.description.length && !localStorage.getItem('runout-card-dismissed')) {
			cards.push(runOutCard);
		}
		if (graceCard.description.length && !localStorage.getItem('grace-card-dismissed')) {
			cards.push(graceCard);
		}

		setAccountPlanCardItems(cards);
	};

	useEffect(() => {
		setAccountCards(summaryData.data?.xfsa_summary);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isHsaAccountClosed, summaryData.data]);

	useEffect(() => {
		setIsLoading(summaryData.loading);
		if (!summaryData.loading) {
			getAccountSummaryData(summaryData.data);

			const isAlldcfsaAccount = summaryData.data?.xfsa_summary.every(
				(account: { type: string }) => account.type == AccountType.DCFSA
			);
			if (
				isAlldcfsaAccount &&
				//dfsaAccount.plan_status == AccountPlanStatus.ACTIVE &&
				summaryData.data?.hsa_summary.length == 0
			) {
				setIsOnlyDFSAccount(true);
				//getCards();
			} else {
				setIsOnlyDFSAccount(false);
				getCards();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [summaryData]);

	useEffect(() => {
		if (
			!isHsaAccountClosed &&
			localStorage.getItem('card-activation-dismissed') !== 'true' &&
			primaryCard &&
			!primaryCard?.is_activated &&
			!isOnlyDFSAccount &&
			!activateCardItems.length &&
			![
				AccountPlanStatus.RUNOUT,
				AccountPlanStatus.READY_TO_CLOSE,
				AccountPlanStatus.PERM_CLOSED
			].includes(summaryData.data.TUserPlanStatus)
		) {
			setActivateCardItems([
				{
					id: 'card-activation-dismissed',
					image: zendaCardImage,
					title: 'It’s time to activate your card!',
					description: ['So you can start spending your funds.'],
					actionTitle: 'Activate Now',
					action: handleActivationCard,
					dismissAction: () => {
						localStorage.setItem('card-activation-dismissed', 'true');
						setActivateCardItems([]);
					}
				}
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOnlyDFSAccount, primaryCard, isHsaAccountClosed]);

	// Take the user to the Trasaction list page
	const redirectToAllPage = () => {
		navigate('/apps/transactions');
	};

	const getExpenseGroup = () => {
		expenseApi
			.getExpenseGroupSummary()
			.then(response => {
				if (
					response.data.data.expense_groups_summary.find(
						(group: any) =>
							group.expense_group.toLowerCase() == ExpenseGroupType.TRAVEL_POTENTIAL && group.count
					) &&
					localStorage.getItem('card-travel-dismissed') !== 'true'
				) {
					setTravelCardItems([
						{
							id: 'card-travel-dismissed',
							image: '',
							title: 'Cover Your Travel Costs?',
							description: [
								'Did you know you can get reimbursed for any travel costs incurred when the primary purpose of the trip was for health reasons?'
							],
							actionTitle: 'Add Travel Expenses',
							action: () => {
								navigate('/apps/expenses/summary?type=TRAVEL_POTENTIAL');
							},
							dismissAction: () => {
								localStorage.setItem('card-travel-dismissed', 'true');
								setTravelCardItems([]);
							}
						}
					]);
				}
			})
			.catch(() => {});
	};

	useEffect(() => {
		getTransaction();
		getProfile();
		dispatch(getAccountSummary());
		getExpenseGroup();

		// (window as any).gtag('event', 'page_view', {
		// 	app_name: 'Zenda-web',
		// 	page_title: 'Dashboard',
		// 	// screen_name: 'Dashboard',
		// 	send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
		// });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// getting everyday balance once acount link message receive from usePlaid
	useEffect(() => {
		if (networkResponse) {
			setLinkSuccessModalIsOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkResponse]);

	// getting network error from background
	useEffect(() => {
		if (networkError && networkError.response.data.error.code == 'NAME_DOES_NOT_MATCH') {
			setInvalidAccountModalIsOpen(true);
		}
	}, [networkError]);

	// Clear the query params after modal is closed
	const clearQueryParams = () => {
		setSearchParams({});
	};
	const closeModal = () => {
		setIsOpen(false);
	};

	if (isLoading || networkLoading) {
		return <Loader />;
	}

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '560px',
			transform: 'translate(-50%, -50%)',
			border: 'none',
			padding: '0px'
		}
	};

	return (
		<div>
			<Helmet>
				<title>InComm Benefits Main Dashboard</title>
			</Helmet>
			<Modal
				isOpen={phoneAddedOpen}
				style={customStyles}
				onRequestClose={() => {
					setPhoneAdded(false);
				}}
				contentLabel="Phone Add"
				onAfterClose={clearQueryParams}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
			>
				<div>
					<div className="modal-header">
						<div className={`text-h2-325 text-white text-center`}>Congrats! </div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img height={200} width={200} className="mt-24 mb-16" src={ConfirmationImage} />
						<p className="text-base-325 text-primary">
							Your account login is created and you can now use your card in store, online and add
							it to mobile wallets.
						</p>
						<br />

						<Button
							type={ButtonTypes.TERTIARY}
							onClick={() => {
								setPhoneAdded(false);
							}}
						>
							Continue
						</Button>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				onRequestClose={closeModal}
				contentLabel="Example Modal"
				onAfterClose={clearQueryParams}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
			>
				<div>
					<div className="modal-header">
						<div className={`text-h3-325 text-white text-center`}>Congrats! </div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img height={200} width={200} className="mt-24  mb-16" src={ConfirmationImage} />
						<p className="text-base-325 text-primary">
							Your account login is created and you can now use your card in store and online.
						</p>
						<br />
						<p className="text-base-325 text-primary">
							Your InComm Benefits card can not be used in mobile wallets until you provide and
							verify your mobile phone number. You can do this from the User Profile screen.
						</p>
						<br />

						<Button
							type={ButtonTypes.TERTIARY}
							onClick={() => {
								setIsOpen(false);
							}}
						>
							Continue
						</Button>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={phoneModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setPhoneModalOpen(false);
				}}
				contentLabel="Phone Modal"
				ariaHideApp={false}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
			>
				{!isPhoneUpdated && (
					<EditPhoneNumber
						closeModal={() => {
							setPhoneModalOpen(false);
						}}
						nextModal={() => {
							getCards();
							setPhoneUpdated(true);
							//setPhoneModalOpen(false);
						}}
						successModal={() => {
							setPhoneVerified(true);
							setPhoneUpdated(true);
						}}
					/>
				)}
				{isPhoneUpdated && (
					<PhoneUpdateSuccess
						phoneVerified={isPhoneVerified}
						card={primaryCard}
						closeModal={() => {
							setPhoneUpdated(false);
							setPhoneModalOpen(false);
							setActivateCardItems([]);
						}}
					/>
				)}
			</Modal>
			<Modal
				isOpen={linkSuccessModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setLinkSuccessModalIsOpen(false);
				}}
				contentLabel="Link Success Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<div className="modal-header">
						<div
							onClick={() => {
								setLinkSuccessModalIsOpen(false);
								dispatch(getAccountSummary());
								setIsLoading(true);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-h3-325 text-white text-center pt-32`}>Success!</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img src={ConfirmationImage} width="180px"></img>
						<p className={`px-64 py-16 text-primary text-base-325`}>
							Your bank account is now linked to InComm Benefits! Now we need you to fund your
							spending account in order to fund your regular purchases.
						</p>
						<div className={`d-flex flex-row flex mt-16 gap-20`}>
							<Button
								type={ButtonTypes.TERTIARY}
								onClick={() => {
									setLinkSuccessModalIsOpen(false);
									dispatch(getAccountSummary());
									setIsLoading(true);
								}}
							>
								Continue
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={invalidAccountModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setInvalidAccountModalIsOpen(false);
				}}
				contentLabel="Link Error Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div className="">
					<div className="modal-header">
						<div
							onClick={() => {
								setInvalidAccountModalIsOpen(false);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-white text-center mt-32 text-h3-325`}>Connect to Your Bank</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img src={linkBankAccountError} width="180px"></img>

						<div className="py-32">
							<p className="text-h2-325 text-center text-primary">Uh oh!</p>

							<p className={`px-64 py-16 text-base-325 text-primary`}>
								The name on the bank account you are trying to link does not match the name on your
								InComm Benefit account.
							</p>
							<p className={`px-64 pt-4 text-base-325 text-primary`}>
								For security purposes we aren’t able to link accounts unless account names match
								exactly.
							</p>
						</div>

						<div className={`d-flex flex-row flex mt-32 gap-20`}>
							<Button
								type={ButtonTypes.PRIMARY}
								onClick={() => {
									setInvalidAccountModalIsOpen(false);
								}}
							>
								TRY ANOTHER ACCOUNT
							</Button>
						</div>
						<p className={'pt-8 text-primary'}>
							<span className="text-base-325 px-4">Need Help?</span>
							<StyledLink
								//href="mailto:support@incommbenefits.com"
								type={StyledLinkTypes.PRIMARY}
								onClick={() => {
									setIsContactUsOpen(true);
								}}
							>
								Contact Us
							</StyledLink>
						</p>
					</div>
				</div>
			</Modal>
			<ContactUs
				openModal={isContactUsOpen}
				closeModal={() => {
					setIsContactUsOpen(false);
				}}
				eventPage="Dashboard - Account Linking - Name Mismatch"
			/>
			<h3 className={classes['dashboard-greet-text']}>
				Welcome{!isNewAccount ? ' back' : ''}, {profile?.first_name}
			</h3>

			{[...accountPlanCardItems, ...activateCardItems, ...travelCardItems].length ? (
				<div className={`d-flex flex-column justify-content-center`}>
					<Slider {...slickSetting}>
						{[...accountPlanCardItems, ...activateCardItems, ...travelCardItems].map(
							(summary, index) => (
								<div key={`${summary.title}-${index}`}>
									<div className={classes['activate-card']}>
										{summary.image && (
											<div className="sm-hide">
												<img src={summary.image} height="140px" />
											</div>
										)}
										<div className={`w-100 ${classes['card-text-content']}`}>
											<p className="text-h5-350 text-primary pb-4">{summary.title}</p>
											<span className="d-flex flex-column gap-12">
												{summary.description.map((description: string) => (
													<p key={description} className="text-sm-325 text-primary">
														{description}
													</p>
												))}
											</span>

											<br />
											<div className="d-flex justify-content-between align-items-center">
												{summary.actionTitle ? (
													<Button
														onClick={() => {
															summary.action();
														}}
													>
														{summary.actionTitle}
													</Button>
												) : (
													<span></span>
												)}
												{!summary.hideDismiss && (
													<StyledLink
														onClick={() => {
															summary.dismissAction();
														}}
													>
														Dismiss
													</StyledLink>
												)}
											</div>
										</div>
									</div>
								</div>
							)
						)}
					</Slider>
				</div>
			) : null}

			<MyAccountDetail
				details={dashboardData.summary}
				cardActivated={[...accountPlanCardItems, ...activateCardItems, ...travelCardItems].length}
			/>
			<br />

			{summaryData.data?.cashback_summary ? (
				<div className={` ${classes['cash-back-card']} p-16 mb-16 d-flex justify-content-between`}>
					<div className={`${classes['cash-back-card-details']}`}>
						<p className="text-primary text-base-700">Cash Back</p>
						<p>
							<span className="text-primary text-h2-325">
								${getDecimalVal(summaryData.data?.cashback_summary?.accrued_amount || 0)}{' '}
							</span>
							<span className="text-secondary text-sm-325">this month</span>
						</p>
						{summaryData.data?.cashback_summary?.redeemable_amount != 0 && (
							<>
								<p className="text-secondary text-sm-325 py-8">
									${getDecimalVal(summaryData.data?.cashback_summary?.redeemable_amount || 0)}{' '}
									available
								</p>
								<div>
									<Button
										size={ButtonSizes.COMPACT}
										onClick={() =>
											navigate('/apps/cashback/redeem', {
												state: {
													isFromDashboard: true
												}
											})
										}
									>
										Ready To Redeem!
									</Button>
								</div>
							</>
						)}
					</div>
					<div className="d-flex align-items-center">
						<span
							className={`${classes['cursor-pointer']} d-flex`}
							onClick={() => {
								navigate('/apps/cashback/details');
							}}
						>
							<ChevronClose title="Open cashback details" titleId="open-cashback" color="#111827" />
						</span>
					</div>
				</div>
			) : (
				''
			)}
			{transactionList?.transactions?.length > 0 ? (
				<>
					<h5 className="text-h5-350 d-flex align-items-center mb-12">Recent Transactions</h5>
					<TransactionTableV2 sections={transactionList.transactions}></TransactionTableV2>
					{transactionList.number_of_transaction >= 10 ? (
						<div className={`d-flex justify-content-center mt-12`}>
							{/* <Button onClick={redirectToAllPage}>View All </Button> */}
							<span className="py-12">
								<StyledLink onClick={redirectToAllPage}>View More Transactions</StyledLink>
							</span>
						</div>
					) : (
						''
					)}
				</>
			) : (
				<>
					<p className="text-h5-350 d-flex align-items-center mb-12">Recent Transactions</p>
					<div className={classes['activity-container']}>
						<div>
							<p className={classes['no-activity-text']}>
								You currently do not have any transactions for display.
							</p>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
