import React, { useState, useEffect } from 'react';
import { Loader } from '../../components/common/Loader';
import Modal from 'react-modal';
import { Incomplete, Message } from '../icons';
import classes from './ContactUs.module.scss';
import { Button, ButtonSizes, ButtonTypes } from '../common/Button';
import messagesentImg from '../../assets/img/ThumbsUp.svg';
import { FormControl } from '../../components/common/FormControl';
import { useForm, Controller } from 'react-hook-form';
import { contactApi } from '../../services/profileService';
import UserPool from '../../utils/UserPool';
import { useNavigate } from 'react-router-dom';
import { ControlType } from '../common/FormControl/FormControl.constants';

export const ContactUs = ({
	openModal,
	closeModal,
	eventPage = ''
}: {
	openModal: boolean;
	closeModal: () => void;
	eventPage: string;
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isMailSent, setIsMailSent] = useState<boolean>(false);
	const [userAttributes, setUserAttributes] = useState<null | Record<
		string,
		string | number | boolean
	>>(null);

	const navigate = useNavigate();

	const {
		control,
		handleSubmit,
		reset,
		getValues,
		formState: { isValid },
		watch: messageWatch
	} = useForm<{ message: string }>({
		mode: 'onChange',
		defaultValues: {
			message: ''
		}
	});

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '564px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			border: 'none',
			backgroundColor: '#fff'
		}
	};
	messageWatch('message');
	const onSubmit = handleSubmit((data: any) => {
		setIsLoading(true);
		const message = `${data.message} [ Source screen: Web - ${eventPage} ]`;

		contactApi
			.contactUs({ message })
			.then(() => {
				setIsMailSent(true);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	});

	const contactUsForm = () => {
		if (isLoading) {
			return <Loader />;
		} else if (isMailSent) {
			return (
				<>
					<div className="modal-header">
						<h2 className={`d-flex justify-content-center text-white text-h3-325 mt-32`}>
							Contact Us
						</h2>
					</div>
					<div
						className={` ${classes['background-blue']} modal-body d-flex flex-column align-items-center`}
					>
						<h3 className={`d-flex justify-center text-yellow text-h1-325 py-16`}>Message Sent</h3>
						<div className="my-48">
							<img src={messagesentImg} width="200"></img>
						</div>

						<br />
						<p className="text-base-700 text-white">Your message has been sent!</p>
						<p className="text-base-700 text-white pt-8">We’ll get back to you ASAP.</p>
						<br />
						<Button
							type={ButtonTypes.TERTIARY}
							onClick={() => {
								reset();
								setIsMailSent(false);
								closeModal();
							}}
						>
							OK
						</Button>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className="modal-header">
						<h2 className={`d-flex justify-content-center text-white text-h3-325 mt-32`}>
							Contact Us
						</h2>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<div className="d-flex pt-24">
							<Message height={68} width={68} color={'#111827'} aria-hidden />
						</div>

						<br />
						<h4 className="text-h4-325 text-center p-12">
							We’re here to make sure you have a great experience with InComm Benefits!
						</h4>
						<form onSubmit={onSubmit} className={`${classes['contact-us-form']} ${classes.form}`}>
							<Controller
								control={control}
								name="message"
								rules={{
									required: 'Message is required.'
								}}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<>
										<FormControl
											label={() => 'Please describe your problem'}
											error={() => error?.message || ''}
											controlType={ControlType.DARK}
										>
											<textarea
												id="contactusinput"
												className="p-12"
												maxLength={1900}
												onChange={onChange}
												value={value}
												rows={10}
												cols={50}
											></textarea>
										</FormControl>
									</>
								)}
							/>
							<div className="pb-24">
								<span className="text-primary">Email address where a response will get sent:</span>
								<div className="d-flex flex-wrap justify-content-between py-4">
									<span className="text-primary">{userAttributes?.email}</span>
									<span>
										<Button
											type={ButtonTypes.TRANSPARENT}
											size={ButtonSizes.DEFAULT}
											otherClasses={classes['update-email-btn']}
											onClick={() => {
												navigate('/apps/email-verify', {
													state: { email: userAttributes?.email || '' }
												});
												closeModal();
											}}
										>
											Update
										</Button>
									</span>
								</div>
							</div>
							<hr aria-hidden="true" className="mb-48" />
							<div className={`d-flex justify-content-center`}>
								<Button
									type={ButtonTypes.PRIMARY}
									isDisabled={!isValid || getValues('message').trim().length <= 0}
								>
									SEND MESSAGE
								</Button>
							</div>
						</form>
					</div>
				</>
			);
		}
	};

	useEffect(() => {
		const cognitoUser = UserPool.getCurrentUser();
		cognitoUser?.getSession((sessionError: any) => {
			if (!sessionError) {
				cognitoUser.getUserAttributes(function (AttributeError, attributes) {
					if (!AttributeError) {
						const attributeObj: Record<string, string | number | boolean> = {};
						attributes?.forEach(attribute => {
							attributeObj[attribute.getName()] = attribute.getValue();
						});
						setUserAttributes(attributeObj);
					}
				});
			}
		});
	}, []);
	return (
		<Modal
			isOpen={openModal}
			style={customStyles}
			onRequestClose={() => {}}
			contentLabel="Contact Us Modal"
			shouldCloseOnEsc={false}
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="">
				<Button
					type={ButtonTypes.TRANSPARENT}
					size={ButtonSizes.DEFAULT}
					onClick={() => {
						reset();
						setIsMailSent(false);
						closeModal();
					}}
					otherClasses={`${classes['close-btn']} modal-close-btn-container`}
				>
					<Incomplete title="Close" titleId="contactus-close-btn"></Incomplete>
				</Button>
				{contactUsForm()}
			</div>
		</Modal>
	);
};
