import React from 'react';
import classes from './ExpenseTable.module.scss';
import { Hsa, HsaExpense } from '../../icons';
import { IExpense } from '../../../interface/common.interface';
import { ExpenseGroupType, TransactionAndExpenseDes } from '../../../constants/common';
import { StyledLink } from '../Link/StyledLink';
import { StyledLinkSizes } from '../Link/StyledLink.constants';
import IconNavLink from '../IconNavLink';
import { ICON_NAV_LINK_TYPES } from '../IconNavLink/IconNavLink.constants';

type ExpenseProps = {
	expenses: Array<{
		sectionName: string;
		data: Array<IExpense>;
	}>;
	hasAction?: boolean;
	dismissAction?: { title: string; icon: any; action: (expenseId: string) => void };
	confirmAction?: { title: string; icon: any; action: (expenseId: string) => void };
	openExpenseIcon?: boolean;
};

export const ExpenseTable = ({
	expenses,
	hasAction,
	dismissAction,
	confirmAction,
	openExpenseIcon = true
}: ExpenseProps) => {
	/**
	 *
	 * @param expense
	 * @returns color of the description based on status
	 */
	const getDesClass = (expense: IExpense) => {
		switch (expense.description?.toLowerCase()) {
			case TransactionAndExpenseDes.RECEIPT_RECOMMENDED:
			case TransactionAndExpenseDes.PARTIALLY_REIMBURSED:
			case TransactionAndExpenseDes.NEEDS_ADDITIONAL_INFO:
				return classes['yellow-pill'];
			case TransactionAndExpenseDes.ELIGIBLE_FOR_REIMBURSEMENT:
				return classes['green-pill'];
			// case TransactionAndExpenseDes.ACTION_NEEDED:
			// 	return classes['blue-text'];
			default:
				return '';
		}
	};
	return (
		<div className="d-flex flex-column">
			{expenses.map((expense, index) => {
				return (
					<div key={`expense-section-${index}`} className="mt-32">
						<h6 className={classes['group-name']}>{expense.sectionName}</h6>
						{expense.data.map((t: any, transaction_index: number) => {
							return (
								<li
									key={`expense-${transaction_index}`}
									className={classes['expense-item-wrapper']}
								>
									<IconNavLink
										key={`expense-${transaction_index}`}
										type={ICON_NAV_LINK_TYPES.LINK}
										href={`/apps/expense?id=${t.id}`}
										title={t.activity}
										mainSubtitle={
											<span className={`text-sm-325 ${getDesClass(t)}`}>{t.description}</span>
										}
										leftIcon={
											t.expense_group == ExpenseGroupType.TRAVEL_POTENTIAL.toUpperCase() ? (
												<HsaExpense width={32} height={32} aria-hidden />
											) : (
												<Hsa width={32} height={32} aria-hidden />
											)
										}
										additionalInfo={<strong>{t.amount}</strong>}
										additionalSubInfo={t.date}
										hasRightArrow={openExpenseIcon}
										wrapperClasses={hasAction ? classes['travel-expense-wrapper'] : ''}
										isLast={!hasAction && expense.data.length - 1 === transaction_index}
									/>
									{hasAction && (
										<div className={`py-8 d-flex justify-content-around ${classes['detail-item']}`}>
											<div
												className={`d-flex align-items-center justify-content-center w-50 ${classes['right-divider']}`}
											>
												<span
													className={`${classes['cursor-pointer']} d-flex justify-content-center`}
												>
													{dismissAction
														? React.cloneElement(dismissAction.icon, {
																title: dismissAction.title,
																titleId: dismissAction.title + transaction_index
														  })
														: ''}
												</span>

												<StyledLink
													size={StyledLinkSizes.SM}
													onClick={() => {
														dismissAction?.action(t.id);
													}}
												>
													<span className="px-2">{dismissAction?.title}</span>
												</StyledLink>
											</div>
											<div className="d-flex align-items-center justify-content-center w-50">
												{confirmAction
													? React.cloneElement(confirmAction.icon, {
															title: confirmAction.title,
															titleId: confirmAction.title + transaction_index
													  })
													: ''}
												<StyledLink
													size={StyledLinkSizes.SM}
													onClick={() => {
														confirmAction?.action(t.id);
													}}
												>
													<span className="px-2">{confirmAction?.title}</span>
												</StyledLink>
											</div>
										</div>
									)}
								</li>
							);
						})}
					</div>
				);
			})}
		</div>
	);
};
