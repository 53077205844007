import { IPaginationConfig } from '../interface/common.interface';
import { APIService } from './apiService';

const API_END_POINT = '/v2/user/transactions';
const API_END_TRANSACTION = '/v2/user/transaction';
const API_END_EXPENSE = '/v2/user/expense';
const API_END_UPDATE_TRAVEL_EXPENSE = '/v2/user/expense';
const API_END_REIMBURSE = '/v2/user/expense';
const API_END_UPLOAD_DOCUMENT = '/v2/user/expense';
const API_END_FIX_ASSERT = '/v2/user/expense';
const API_END_CREATE_EXPENSE = '/v2/user/expense';
const API_END_GET_EXPENSE = '/v2/user/expenses';
const API_END_GET_EXPENSE_GROUP = '/v2/user/expenses/';
const API_END_EXPENSE_DELETE = '/v2/user/expense';
const API_END_EXPENSE_DELETE_ATTACHMENT = '/v2/user/expense';
const API_FIX_MISTAKEN_WITHDRAWAL = '/v2/user/expense/hsa/correction';
const API_CASHBACK_REDEMPTION = '/v2/user/cashback/redemptions';
const API_CASHBACK_TRANSACTION = '/v2/user/cashback/transactions';

export const transactionsApi = {
	fetchTransactions(paginationConfig: IPaginationConfig) {
		return APIService.get(API_END_POINT, paginationConfig);
	},
	getTransactionsDetails(id: number | string) {
		return APIService.get(`${API_END_TRANSACTION}/${id}`);
	},
	getExpenseDetails(id: number | string) {
		return APIService.get(`${API_END_EXPENSE}/${id}`);
	},
	updateTravelExpense(id: number | string, data: any) {
		return APIService.put(`${API_END_UPDATE_TRAVEL_EXPENSE}/${id}`, data);
	},
	reImburseExpense(id: number | string, data: any) {
		return APIService.post(`${API_END_REIMBURSE}/${id}`, data);
	},
	uploadDocument(id: number | string, data: any) {
		return APIService.post(`${API_END_UPLOAD_DOCUMENT}/${id}/upload`, data);
	},
	fixAssert(id: number | string, data: any) {
		return APIService.post(`${API_END_FIX_ASSERT}/${id}/fix-assert`, data);
	},
	createExpense(data: any) {
		return APIService.post(`${API_END_CREATE_EXPENSE}`, data);
	},
	getExpense() {
		return APIService.get(`${API_END_GET_EXPENSE}`);
	},
	getExpenseByGroup(expenseGroup: string, paginationConfig: IPaginationConfig) {
		return APIService.get(`${API_END_GET_EXPENSE_GROUP}${expenseGroup}`, paginationConfig);
	},
	deleteExpense(expenseId: string) {
		return APIService.delete(`${API_END_EXPENSE_DELETE}/${expenseId}`);
	},
	deleteAttachment(expenseId: string, attachmentFiles: { delete_file_list: Array<string> }) {
		return APIService.delete(
			`${API_END_EXPENSE_DELETE_ATTACHMENT}/${expenseId}/attachments`,
			attachmentFiles
		);
	},
	fixMistakenWithdrawal(data: any) {
		return APIService.post(API_FIX_MISTAKEN_WITHDRAWAL, data);
	},
	dismissTransaction(expenseId: string, data: Array<{ op: string; path: string; value: string }>) {
		return APIService.patch(`${API_END_EXPENSE}/${expenseId}`, data);
	},
	cashbackRedemption(data: IPaginationConfig) {
		return APIService.get(API_CASHBACK_REDEMPTION, data);
	},
	cashbackTransaction(data: IPaginationConfig & { redemption_id: string }) {
		return APIService.get(API_CASHBACK_TRANSACTION, data);
	}
};
